import PropTypes from 'prop-types';
import { getPhoneParts } from '../../util/phone';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BikeIcon } from '../../assets/icons/header/bike.svg';
import { Link } from 'react-router-dom';
import { days } from '../../util/dates';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { getTotal } from '../../util/cart';
import { getMinPriceForFreeDelivery } from '../../util/api';

function PhoneDisplay({ phone }) {
  const phoneParts = getPhoneParts(phone);

  return (
    <a href={`tel:+${phone}`} className="simple-flex justify-center">
      <span className="phone-icon-wrapper">
        <span className="icon-phone" />
      </span>
      <span className="header-phone-number-wrapper">
        <span className="header-title">Единый телефон:</span>
        <span className="phone-value">
          {phoneParts[0]}
          <span>{phoneParts[1]}</span>
        </span>
      </span>
    </a>
  );
}

PhoneDisplay.propTypes = {
  phone: PropTypes.string.isRequired,
};

function ToolbarLeft({ phone, minPrice, openingTime }) {
  const dispatch = useDispatch();

  const deliveryDays = openingTime[0].days;
  const deliveryTime = openingTime[0].span;

  const userCart = useSelector((state) => state.userCart.userCart);
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const minPriceForFreeDelivery = useSelector(
    (state) => state.orders.minPriceForFreeDelivery,
  );

  const freeDeliveryStatus = useMemo(() => {
    if (!userCart || !isAuthorized || !userInfo?.address) return null;

    if (!minPriceForFreeDelivery) return 'Бесплатная доставка невозможна';

    const totalPrice = getTotal('price', userCart);

    if (minPriceForFreeDelivery - totalPrice <= 0) {
      return 'Бесплатная доставка';
    }

    return `До бесплатной доставки: ${minPriceForFreeDelivery - totalPrice} руб`;
  }, [minPriceForFreeDelivery, isAuthorized, userCart]);

  useEffect(() => {
    if (!userInfo || !userInfo.address) return;
    const data = {
      address: {
        street: userInfo.address?.street,
        house: userInfo.address?.house,
        corp: userInfo.address?.corp,
        ...(userInfo.address?.city && { city: userInfo.address.city }),
        ...(userInfo.address?.settlement && {
          settlement: userInfo.address.settlement,
        }),
      },
    };
    getMinPriceForFreeDelivery(data, dispatch);
  }, [userInfo]);

  return (
    <div className="simple-flex header-content-left">
      <PhoneDisplay phone={phone} />
      <Link
        className="simple-flex"
        to="/условия-доставки"
        title="Условия доставки"
      >
        <SvgIcon
          component={BikeIcon}
          classes={{ root: 'header-bike-icon' }}
          viewBox="0 0 25 25"
        />
        <span className="header-opening-time-wrapper">
          <span className="opening-time-days">
            {`${days[deliveryDays.from]}-${days[deliveryDays.to]}`}
            <span className="opening-time-hours">{`${deliveryTime[0]}-${deliveryTime[1]}`}</span>
          </span>
          <span className="header-title">Доставка от {minPrice} руб</span>
          {freeDeliveryStatus && (
            <span className="header-free-delivery-title">
              {freeDeliveryStatus}
            </span>
          )}
        </span>
      </Link>
    </div>
  );
}

ToolbarLeft.propTypes = {
  phone: PropTypes.string.isRequired,
  minPrice: PropTypes.number.isRequired,
  openingTime: PropTypes.array.isRequired,
};

export default ToolbarLeft;

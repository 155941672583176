import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import TheDropdown from '../../reusable/TheDropdown/TheDropdown';
import TheCheckbox from '../../reusable/TheCheckbox/TheCheckbox';
import * as actionCreators from '../../store/actions';
import clsx from 'clsx';
import {
  DEFAULT_DELIVERY_STATUS,
  PHONE_MASK,
  ROLES,
  SPECIAL_ROLES,
  PRESENCES,
  ORDER_COOKIES,
} from '../../util/constants';
import {
  validateLoyalty,
  validatePositiveNumberField,
} from '../../util/validators';
import {
  findObjInArrayByValue,
  getClientSideId,
  getCloneObjWithoutKeys,
  getPositiveNumberFromLimit,
  scrollToFirstSelector,
} from '../../util/misc';
import { Link, useHistory } from 'react-router-dom';
import { setCookie, getCookie, deleteCookie } from '../../util/cookies';
import {
  getMaxLoyaltyValue,
  mapOptions,
  getIsFreeDelivery,
  getTotalAmountAfterAllDiscounts,
} from '../../util/orders';
import { getTotal } from '../../util/cart';
import ClickOutsideButton from '../../components/clickOutsideButtons/ClickOusideButton';
import { DeliveryAddressSelectorWithoutMap } from '../../reusable/DeliveryAdressSelector/DeliveryAddressSelectorWithoutMap';
import MaskedInput from 'react-text-mask';
import { extractPhoneNumbers } from '../../util/phone';
import { Divider } from '../../reusable/Divider/Divider';
import PickDeliveryMethod from './PickDeliveryMethod';
import { ReactComponent as Alert } from '../../assets/icons/orders/alert.svg';
import './PlaceOrder.scss';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import {
  setDiscountAmount,
  promocodeAdded,
  setPromocodeStatus,
} from '../../store/orders/orders';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

const TAKE_OVER_PERSONS_AMOUNT_OPTIONS = Array.from({ length: 10 }, (_, i) => ({
  key: i,
  value: i === 0 ? 'Приборы не нужны' : `${i}`,
}));

const STAY_PRESENCE_PERSONS_AMOUNT_OPTIONS = Array.from(
  { length: 10 },
  (_, i) => ({
    key: i,
    value: `${i}`,
  }),
).slice(1);

const PlaceOrder = () => {
  useTitleUpdate('Оформление заказа');
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );

  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const initialDeliveryTime = useSelector(
    (state) => state.currentUser.userInfo?.delivery_time,
  );
  const minPriceForFreeDelivery = useSelector(
    (state) => state.orders.minPriceForFreeDelivery,
  );
  const initialRestaurantId = userInfo?.takeaway_restaurant;
  const restaurants = useSelector((state) => state.companyInfo.contacts);
  const mappedRestaurants = mapOptions(restaurants, 'id', 'address', 'name');
  const initialRestaurant = mappedRestaurants.find(
    (el) => el.key === initialRestaurantId,
  );

  const isCallCenterAccount =
    userInfo?.role === ROLES.operator ||
    userInfo?.phone === SPECIAL_ROLES.callCenterPhone;

  const savedClientName = getCookie(ORDER_COOKIES.SAVED_CLIENT_NAME);
  const savedClientPhone = getCookie(ORDER_COOKIES.SAVED_CLIENT_PHONE);
  const savedPresence = getCookie(ORDER_COOKIES.SAVED_PRESENCE);
  const savedRestaurant = getCookie(ORDER_COOKIES.SAVED_RESTAURANT);
  const savedPaymentType = getCookie(ORDER_COOKIES.SAVED_PAYMENT_TYPE);
  const savedPeopleAmount = getCookie(ORDER_COOKIES.SAVED_PEOPLE_AMOUNT);
  const savedComment = getCookie(ORDER_COOKIES.SAVED_COMMENT);
  const savedDeliveryTime = getCookie(ORDER_COOKIES.SAVED_DELIVERY_TIME);
  const savedShouldNotCall = getCookie(ORDER_COOKIES.SAVED_SHOULD_NOT_CALL);
  const savedLoyalty = getCookie(ORDER_COOKIES.SAVED_LOYALTY);
  const savedPromocode = getCookie(ORDER_COOKIES.SAVED_PROMOCODE);
  const savedRemember = getCookie(ORDER_COOKIES.SAVED_REMEMBER);
  const savedCashChangeNotNeeded = getCookie(
    ORDER_COOKIES.SAVED_CASH_CHANGE_NOT_NEEDED,
  );
  const savedCashChange = getCookie(ORDER_COOKIES.SAVED_CASH_CHANGE);

  const deliveryTimes = useSelector((state) => state.orders.deliveryTimes);
  const [deliveryTime, setDeliveryTime] = useState(initialDeliveryTime);

  const mappedDeliveryTimes = useMemo(
    () => mapOptions(deliveryTimes, 'stamp', 'name'),
    [deliveryTimes],
  );

  const savedDeliveryTimeOption = useMemo(
    () =>
      mappedDeliveryTimes.find(
        (mappedDeliveryTime) => mappedDeliveryTime.key === savedDeliveryTime,
      ),
    [savedDeliveryTime, mappedDeliveryTimes],
  );

  const paymentTypes = useSelector((state) => state.orders.paymentTypes);
  const paymentTypesPresent = !!paymentTypes.length;
  const mappedPaymentTypes = mapOptions(paymentTypes, 0, 1);

  const companyInfo = useSelector((state) => state.companyInfo.info);
  const lastComments = useSelector((state) => state.orders.lastComments);
  const promocodeAddedStatus = useSelector(
    (state) => state.orders.promocodeAdded,
  );
  const discount = useSelector((state) => state.orders.discount);
  const discountAmount = useSelector((state) => state.orders.discountAmount);
  const userCart = useSelector((state) => state.userCart.userCart);
  const presences = useSelector((state) => state.orders.presences);

  const totalCost = useMemo(() => {
    if (userCart) {
      return getTotal('price', userCart) || 0;
    }
  }, [userCart]);

  const maxPersentPayedByLoyalty = companyInfo?.percent_pay_by_loyalty;
  const minDeliveryPrice = companyInfo?.delivery_min_price;

  const userLoyaltyPoints = userInfo?.loyalty_points;
  const showDeliveryContent = totalCost >= minDeliveryPrice;

  const [isDelivery, setIsDelivery] = useState(
    (savedPresence || userInfo?.presence_state) === PRESENCES.delivery,
  );
  const [deliveryStatus, setDeliveryStatus] = useState(DEFAULT_DELIVERY_STATUS);
  const [requiredAddressFieldsTouched, setRequiredAddressFieldsTouched] =
    useState(false);
  const [address, setAddress] = useState({});
  const [isPrevCommentsVisible, setIsPrevCommentsVisible] = useState(false);

  const [userSavedRestaurantId, setUserSavedRestaurantId] = useState(
    savedRestaurant?.key || initialRestaurant?.key,
  );

  const deliveryPrice = getDeliveryPrice();

  function getDeliveryPrice() {
    if (!isDelivery) {
      return 0;
    }
    return deliveryStatus.price ?? userInfo.delivery_price ?? 0;
  }

  const maxLoyaltyValue = getMaxLoyaltyValue({
    totalCost,
    maxPersentPayedByLoyalty,
    minDeliveryPrice,
    isDelivery,
  });

  const isStayPresence =
    (savedPresence || userInfo?.presence_state) === PRESENCES.stay;
  const personsAmountOptions = isStayPresence
    ? STAY_PRESENCE_PERSONS_AMOUNT_OPTIONS
    : TAKE_OVER_PERSONS_AMOUNT_OPTIONS;
  const stayPresenceAmount =
    savedPeopleAmount?.key === TAKE_OVER_PERSONS_AMOUNT_OPTIONS[0].key
      ? STAY_PRESENCE_PERSONS_AMOUNT_OPTIONS[0]
      : savedPeopleAmount;
  const initialPersonsAmount = isStayPresence
    ? stayPresenceAmount
    : savedPeopleAmount;

    const costAfterAmountDiscount = useMemo(() => {
      if (discountAmount === null) {
        return null;
      }
      return Math.max(totalCost - discountAmount, 0);
    }, [discountAmount, totalCost]);

  const costAfterDiscount = useMemo(() => {
    if (discount === null) {
      return null;
    }
    return (totalCost * (100 - discount)) / 100;
  }, [discount, totalCost]);

  useEffect(() => {
    const newVal =
      (savedPresence || userInfo?.presence_state) === PRESENCES.delivery;
    if (isDelivery === newVal) return;
    setIsDelivery(newVal);
  }, [userInfo?.presence_state]);

  useBreadcrumbs(
    'inner-container inner-container--xs',
    'корзина/Оформление заказа',
  );

  useEffect(() => {
    if (!paymentTypesPresent && !isLoadingFailed && isAuthorized) {
      dispatch(actionCreators.getDeliveryInfo(!!restaurants.length));
    }

    return () => {
      dispatch(actionCreators.setInitialState());
    };
  }, [isAuthorized]);

  // load delivery times if user change delivery method or change restaurant
  useEffect(() => {
    if (!isDelivery && userSavedRestaurantId && isAuthorized) {
      dispatch(
        actionCreators.getRestaurantDeliveryTimes(userSavedRestaurantId),
      );
    }
  }, [isDelivery, userSavedRestaurantId, isAuthorized]);

  useEffect(() => {
    setDeliveryTime(initialDeliveryTime);
  }, [initialDeliveryTime]);

  // when user changes restaurant - we need to update delivery time
  // if new restaurant has different delivery time - we need to update it
  useEffect(() => {
    if (mappedDeliveryTimes.length) {
      const findDeliveryTime = findObjInArrayByValue(
        deliveryTime,
        mappedDeliveryTimes,
        'key',
      );
      setDeliveryTime(findDeliveryTime?.key);
    }
  }, [mappedDeliveryTimes, deliveryTime]);

  const selectComment = (comment, setPrevCommentsVisible, setFieldValue) => {
    setCookie(ORDER_COOKIES.SAVED_COMMENT, comment);
    setFieldValue('comment', comment);
    setIsPrevCommentsVisible(false);
  };

  if (!companyInfo || !userInfo || !paymentTypesPresent) {
    return null;
  }

  const promocodeHandler = (values) => {
    if (!promocodeAddedStatus) {
      if (values.promo_code.length) {
        dispatch(
          actionCreators.checkPromoCode(
            values.promo_code,
            savedPresence || userInfo?.presence_state,
          ),
        );
      } else {
        console.log('поле с промокодом пустое');
      }
    } else {
      dispatch(setDiscountAmount(null));
      dispatch(promocodeAdded(null));
      dispatch(setPromocodeStatus(false));
      deleteCookie('savedPromocode');
      values.promo_code = '';
    }
  };

  const setOrdersNumberToLocalStorage = (currentOrderNumber) => {
    const orders = JSON.parse(localStorage.getItem('orders'));
    if (orders) {
      orders.push(currentOrderNumber);
      return localStorage.setItem('orders', JSON.stringify(orders));
    }
    localStorage.setItem('orders', JSON.stringify([currentOrderNumber]));
  };

  return (
    <div className="inner-container inner-container--xs">
      <div className="section-wrapper-medium place-orders">
        <Formik
          initialValues={{
            client_name: savedClientName ?? '',
            client_phone: savedClientPhone ?? '',
            presence: savedPresence || userInfo?.presence_state,
            remember: savedRemember ?? true,
            should_not_call: savedShouldNotCall ?? false,
            payment_type: savedPaymentType?.key,
            delivery_time: deliveryTime,
            restaurant:
              savedRestaurant?.key || initialRestaurant?.key || undefined,
            people_amount: savedPeopleAmount?.key,
            comment: savedComment ?? '',
            loyalty: savedLoyalty ?? '',
            promo_code: savedPromocode ?? '',
            cash_change_not_needed: savedCashChangeNotNeeded ?? false,
            cash_change: savedCashChange ?? '',
          }}
          onSubmit={async (values) => {
            const data = {
              client_phone:
                values.client_phone.length === 12
                  ? values.client_phone
                  : userInfo.phone,
              client_name: values.client_name || userInfo.first_name,
              payment_type: values.payment_type,
              should_not_call: values.should_not_call,
              presence: values.presence,
              people_amount: values.people_amount,
              comment: values.comment,
              pay_loyalty: values.loyalty || 0,
              clientside_id: getClientSideId(),
              promo_code: values.promo_code || null,
              cash_change: values.cash_change || 0,
              remember: values.remember,
              is_need_cash_change: !values.cash_change_not_needed,
              // на ангуляровском фронте эти показатели передаются как пустая строка и null
              loyalty_token: '',
              utm: null,
              is_asap: values.delivery_time === '-',
            };
            if (isDelivery) {
              data.address = getCloneObjWithoutKeys(address, 'id');
              data.restaurant = deliveryStatus.restaurant;
              data.delivery_price = deliveryStatus.price;
            } else {
              data.restaurant = values.restaurant;
            }
            if (data.remember) {
              dispatch(actionCreators.setNeedReloadUserData(true));
            }
            if (!data.is_asap) {
              data.delivery_time = values.delivery_time;
            }
            await dispatch(
              actionCreators.postOrder(
                data,
                history,
                setOrdersNumberToLocalStorage,
              ),
            );
          }}
          validate={(values) => {
            const errors = {};

            if (
              isCallCenterAccount &&
              !!values.client_name &&
              (values.client_name.length > 150 || values.client_name.length < 3)
            ) {
              errors.client_name = 'введите имя';
            }

            if (
              isCallCenterAccount &&
              !!values.client_phone &&
              values.client_phone.length !== 12 &&
              values.client_phone.length !== 2
            ) {
              errors.client_phone = 'введите номер телефона';
            }

            if (!values.restaurant && !isDelivery) {
              errors.restaurant = 'выберите ресторан';
            }
            if (!values.delivery_time) {
              errors.delivery_time = isDelivery
                ? 'выберите время доставки'
                : 'выберите время';
            }
            if (values.people_amount === undefined) {
              errors.people_amount = 'укажите количество персон';
            }
            if (!values.payment_type) {
              errors.payment_type = 'укажите способ оплаты';
            }
            if (
              !values.cash_change &&
              !values.cash_change_not_needed &&
              values.payment_type === 'cash' &&
              isDelivery
            ) {
              errors.cash_change = 'укажите купюру';
            }

            const loyaltyError = validateLoyalty(
              values.loyalty,
              maxLoyaltyValue,
              userLoyaltyPoints,
            );
            if (loyaltyError) {
              errors.loyalty = loyaltyError;
            }
            return errors;
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            isSubmitting,
            values,
            setFieldValue,
            handleSubmit,
            setTouched,
            validateForm,
          }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                setRequiredAddressFieldsTouched(true);
                setTouched(
                  {
                    client_name: true,
                    client_phone: true,
                    payment_type: true,
                    delivery_time: true,
                    restaurant: true,
                    people_amount: true,
                    loyalty: true,
                    cash_change: true,
                  },
                  true,
                );
                validateForm().then((errors) => {
                  if (
                    !Object.keys(errors).length &&
                    (deliveryStatus.isPositive || !isDelivery)
                  ) {
                    handleSubmit();
                  } else {
                    scrollToFirstSelector(
                      '.text-field__error-message',
                      '.text-field--error',
                      '.place-order__delivery-address',
                    );
                  }
                });
              }}
            >
              {isCallCenterAccount && (
                <>
                  <label className="place-order__label">
                    <span className="dropdown__label place-order__label__description">
                      Имя:
                    </span>
                    <Field
                      name="client_name"
                      className="text-field__input place-order__comment-field"
                      placeholder="введите имя"
                      maxLength="150"
                    />
                    {!!errors.client_name && touched.client_name && (
                      <span className="text-field__error-message place-order__label__error">
                        {errors.client_name}
                      </span>
                    )}
                  </label>

                  <label className="place-order__label">
                    <span className="dropdown__label place-order__label__description">
                      Номер телефона:
                    </span>
                    <MaskedInput
                      className="text-field__input place-order__comment-field place-order__phone-field"
                      mask={PHONE_MASK}
                      onChange={(ev) =>
                        setFieldValue(
                          'client_phone',
                          extractPhoneNumbers(ev.target.value),
                        )
                      }
                      showMask
                    />
                    {!!errors.client_phone && touched.client_phone && (
                      <span className="text-field__error-message place-order__label__error">
                        {errors.client_phone}
                      </span>
                    )}
                  </label>
                </>
              )}
              <PickDeliveryMethod
                presences={presences}
                setFieldValue={setFieldValue}
                setIsDelivery={setIsDelivery}
                savedPresence={savedPresence}
                userInfo={userInfo}
              />
              {isDelivery && !showDeliveryContent && (
                <div className="place-order__delivery-error-text">
                  Минимальная сумма при доставке {minDeliveryPrice}&#8381;
                  <br />
                  Перейти в{' '}
                  <Link className="green-link" to="/">
                    меню
                  </Link>{' '}
                  или{' '}
                  <Link className="green-link" to="/корзина">
                    корзину
                  </Link>
                </div>
              )}
              {!isDelivery && (
                <>
                  <div
                    className={clsx('place-orders__dropdown', {
                      'text-field--error': errors.restaurant,
                    })}
                  >
                    <TheDropdown
                      options={mappedRestaurants}
                      title="Ресторан"
                      setValue={(option) => {
                        setFieldValue('restaurant', option?.key);
                        setCookie(ORDER_COOKIES.SAVED_RESTAURANT, option, 1);
                        setUserSavedRestaurantId(option?.key);
                      }}
                      initialOption={savedRestaurant || initialRestaurant}
                    />
                    <ErrorMessage
                      component="span"
                      className="text-field__error-message"
                      name="restaurant"
                    />
                  </div>
                  <TheCheckbox
                    handleBlur={(e) => {
                      handleBlur(e);
                      setCookie(ORDER_COOKIES.SAVED_REMEMBER, values.remember);
                    }}
                    containerClass="place-order__checkbox"
                    name="remember"
                    autoComplete="false"
                    label="Запомнить ресторан как основной"
                    modifier="sm"
                  />
                </>
              )}
              {isDelivery && showDeliveryContent && (
                <>
                  <DeliveryAddressSelectorWithoutMap
                    setSelectedAddress={setAddress}
                    deliveryStatus={deliveryStatus}
                    setDeliveryStatus={setDeliveryStatus}
                    requiredFieldsTouched={requiredAddressFieldsTouched}
                  />

                  <TheCheckbox
                    handleBlur={handleBlur}
                    containerClass="place-order__checkbox"
                    name="remember"
                    autoComplete="false"
                    label="Запомнить адрес доставки как основной"
                    modifier="sm"
                  />
                </>
              )}
              {(!isDelivery || showDeliveryContent) &&
                !!mappedDeliveryTimes.length && (
                  <>
                    <div
                      className={clsx('place-orders__dropdown', {
                        'text-field--error': errors.delivery_time,
                      })}
                    >
                      <TheDropdown
                        options={mappedDeliveryTimes}
                        title={isDelivery ? 'Время доставки:' : 'Время:'}
                        setValue={(option) => {
                          setFieldValue('delivery_time', option?.key);
                          option?.key && setDeliveryTime(option?.key);
                          setCookie(
                            ORDER_COOKIES.SAVED_DELIVERY_TIME,
                            option?.key,
                          );
                        }}
                        initialOption={savedDeliveryTimeOption}
                      />
                      <ErrorMessage
                        component="span"
                        className="text-field__error-message"
                        name="delivery_time"
                      />
                    </div>

                    {userInfo?.show_should_not_call ? (
                      <TheCheckbox
                        label="Отказаться от звонка"
                        name="should_not_call"
                        handleBlur={(e) => {
                          setCookie(
                            ORDER_COOKIES.SAVED_SHOULD_NOT_CALL,
                            values.should_not_call,
                          );
                          handleBlur(e);
                        }}
                        autoComplete="false"
                        containerClass="place-order__checkbox"
                        modifier="sm"
                      />
                    ) : (
                      <span className="place-orders__alert">
                        <Alert className="place-orders__alert-icon" /> Оператор
                        позвонит вам, чтобы подтвердить заказ
                      </span>
                    )}
                    <div
                      className={clsx('place-orders__dropdown', {
                        'text-field--error': errors.people_amount,
                      })}
                    >
                      <TheDropdown
                        options={personsAmountOptions}
                        title="Количество персон:"
                        setValue={(option) => {
                          setFieldValue('people_amount', option?.key);
                          setCookie(
                            ORDER_COOKIES.SAVED_PEOPLE_AMOUNT,
                            option,
                            1,
                          );
                        }}
                        initialOption={initialPersonsAmount}
                      />
                      <ErrorMessage
                        component="span"
                        className="text-field__error-message"
                        name="people_amount"
                      />
                    </div>
                  </>
                )}
              {(!isDelivery || showDeliveryContent) && (
                <>
                  <div
                    className={clsx('place-orders__dropdown', {
                      'text-field--error': errors.payment_type,
                    })}
                  >
                    <TheDropdown
                      options={mappedPaymentTypes}
                      title="Способ оплаты:"
                      setValue={(option) => {
                        setFieldValue('payment_type', option?.key);
                        setCookie(ORDER_COOKIES.SAVED_PAYMENT_TYPE, option, 1);
                      }}
                      initialOption={savedPaymentType}
                    />
                    <ErrorMessage
                      component="span"
                      className="text-field__error-message"
                      name="payment_type"
                    />
                  </div>
                  {values.payment_type === 'cash' && (
                    <>
                      <TheCheckbox
                        label="Без сдачи"
                        name="cash_change_not_needed"
                        handleBlur={(e) => {
                          handleBlur(e);
                          setCookie(
                            ORDER_COOKIES.SAVED_CASH_CHANGE_NOT_NEEDED,
                            values.cash_change_not_needed,
                          );
                        }}
                        autoComplete="false"
                        containerClass="place-order__checkbox"
                        modifier="sm"
                      />
                      {!values.cash_change_not_needed && (
                        <div
                          className={clsx('place-order__change', {
                            'text-field--error': errors.cash_change,
                          })}
                        >
                          <div className="place-order__change-title">
                            Нужна сдача с:
                          </div>
                          <div className="text-field place-order__text-field">
                            <Field
                              className="text-field__input"
                              type="text"
                              placeholder="Укажите купюру"
                              onChange={(e) => {
                                const value = validatePositiveNumberField(
                                  e.target.value,
                                );
                                setFieldValue('cash_change', value);
                                setCookie(
                                  ORDER_COOKIES.SAVED_CASH_CHANGE,
                                  value,
                                );
                              }}
                              name="cash_change"
                            />
                          </div>
                          <ErrorMessage
                            component="span"
                            className="text-field__error-message"
                            name="cash_change"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="place-order__comment-wrapper">
                    <ClickOutsideButton
                      addressField={false}
                      fieldFunction={selectComment}
                      isVisible={isPrevCommentsVisible}
                      fieldData={lastComments}
                      setIsVisible={setIsPrevCommentsVisible}
                      setFieldValue={setFieldValue}
                    />
                    <Field
                      name="comment"
                      className="text-field__input place-order__comment-field"
                      placeholder="Комментарий"
                      maxLength="250"
                      onBlur={() =>
                        setCookie(
                          ORDER_COOKIES.SAVED_COMMENT,
                          values.comment,
                          1,
                        )
                      }
                    />
                  </div>

                  <Divider className="place-order__divider" />

                  <div className="coupon">
                    <div className="coupon__title">Введите промокод:</div>
                    <div className="coupon__container">
                      <Field
                        disabled={promocodeAddedStatus}
                        className="coupon__input text-field__input"
                        type="text"
                        name="promo_code"
                        placeholder="Промокод"
                        onBlur={() =>
                          setCookie(
                            ORDER_COOKIES.SAVED_PROMOCODE,
                            values.promo_code,
                          )
                        }
                      />
                      {/* TODO Add styles for disabled button and success */}
                      <button
                        className={`btn coupon__btn ${promocodeAddedStatus && 'coupon__btn_remove'}`}
                        type="button"
                        onClick={() => {
                          promocodeHandler(values);
                        }}
                      >
                        {promocodeAddedStatus ? 'отменить' : 'применить'}
                      </button>
                    </div>
                  </div>
                  {!discount && !discountAmount && (
                    <>
                      <Divider className="place-order__divider" />
                      <div className="place-order__bonus-payment bonus-payment">
                        <div className="bonus-payment__title">
                          Оплата бонусными баллами:
                        </div>
                        <div
                          className={clsx(
                            'text-field bonus-payment__text-field',
                            {
                              'text-field--error':
                                errors.loyalty && touched.loyalty,
                            },
                          )}
                        >
                          <Field
                            className="text-field__input"
                            type="text"
                            placeholder="Кол-во баллов для списания"
                            maxLength={6}
                            name="loyalty"
                            onFocus={() => setTouched({ loyalty: true })}
                            onChange={(ev) => {
                              const value = validatePositiveNumberField(
                                ev.target.value,
                              );
                              setFieldValue('loyalty', value);
                              setCookie(ORDER_COOKIES.SAVED_LOYALTY, value);
                            }}
                          />
                          <ErrorMessage
                            component="span"
                            className="text-field__error-message"
                            name="loyalty"
                          />
                        </div>
                        <div className="bonus-payment__info">
                          <div className="bonus-payment__info-text">
                            {values.loyalty === ''
                              ? `Максимальное количество бонусных баллов к списанию: `
                              : `Можно еще списать баллов: `}
                            <span>
                              {maxLoyaltyValue > userLoyaltyPoints
                                ? userLoyaltyPoints
                                : values.loyalty > maxLoyaltyValue
                                  ? 0
                                  : maxLoyaltyValue - values.loyalty}
                            </span>
                          </div>
                          <div className="bonus-payment__info-text">
                            У вас бонусных баллов:{' '}
                            <span>{userLoyaltyPoints}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Divider className="place-order__divider" />
                  <div className="dishes-price">
                    <span className="price-button-wrapper__title">
                      Сумма заказа:
                    </span>
                    <span className="price-button-wrapper__value">
                      {totalCost}
                      <span className="rub-sign"> ₽</span>
                    </span>
                  </div>
                  {isDelivery && (getIsFreeDelivery(
                    costAfterDiscount, 
                    costAfterAmountDiscount, 
                    getPositiveNumberFromLimit(
                      values.loyalty,
                      maxLoyaltyValue,
                      userLoyaltyPoints,
                    ), 
                    totalCost,
                    minPriceForFreeDelivery
                  ) ? (
                    <div className="delivery-price">
                      <span className="price-button-wrapper__title-free">
                        Бесплатная доставка
                      </span>
                    </div>
                  ) : (
                    <div className="delivery-price">
                      <span className="price-button-wrapper__title">
                        Доставка:
                      </span>
                      <span className="price-button-wrapper__value">
                        {deliveryPrice}
                        <span className="rub-sign"> ₽</span>
                      </span>
                    </div>
                  ))}
                  <div className="price-btn-wrapper">
                    <div className="price-button-wrapper__price">
                      <span className="price-button-wrapper__title">
                        Сумма к оплате:
                      </span>
                      <span className="price-button-wrapper__value">
                        {getTotalAmountAfterAllDiscounts({
                          costAfterDiscount,
                          costAfterAmountDiscount,
                          totalCost,
                          positiveNumberFromLimit: getPositiveNumberFromLimit(
                            values.loyalty,
                            maxLoyaltyValue,
                            userLoyaltyPoints,
                          ),
                          deliveryPrice,
                          minPriceForFreeDelivery,
                        })}
                        <span className="rub-sign"> ₽</span>
                      </span>
                    </div>
                    <div
                      className={`place-order__submit-btn-container ${isSubmitting && 'place-order__submit-btn-container_small'}`}
                    >
                      {!isSubmitting ? (
                        <button
                          className="btn price-btn-wrapper__btn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Отправить заказ
                        </button>
                      ) : (
                        <LoadingSpinner size={50} wholePage={false} />
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PlaceOrder;

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { MetrikaCounters } from 'react-metrika';

export function YandexMetrika() {
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const yandexIds = useMemo(() => {
    if (!companyInfo?.counters_js_head) return null;

    const matchedIds = Array.from(
      companyInfo.counters_js_head.matchAll(
        /"https:\/\/mc.yandex.ru\/watch\/(\d*)"/g,
      ),
    );

    if (matchedIds.length === 0) return null;

    return matchedIds.map((match) => match.at(-1)).map((id) => Number(id));
  }, [companyInfo]);

  if (!yandexIds) return null;

  return (
    <MetrikaCounters
      ids={yandexIds}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        ecommerce: 'dataLayer',
      }}
    />
  );
}
